import {formatDistanceToNow} from "date-fns";
import {trash} from "./icons";
import React from "react";

export default function SingleColumn({settings, todos, toggleTodo, deleteTodo}) {

    return (<ul className={'list'}>
        {todos.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)).map((todo, i) => {

            const listItemStyle = {
                backgroundColor: todo.completed ? 'rgba(0,0,0,.2)' : 'rgba(255,255,255,.2)',
                padding: settings.compact ? '2em .75em' : '3em 1em'
            }

            return (<>
                {i === 0 && <p className={'message'}>Latest</p>}
                {todos[i - 1] && todos[i - 1].createdAtDate && todos[i - 1].createdAtDate !== todo.createdAtDate &&
                <p className={'message'}>{todo.createdAtDate}</p>}
                <style>{`ul li::before{background-color:${settings.dark ? '#FFF' : '#7D7D7D'}} ul li::after{background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%23${settings.dark ? 'FFF' : '7D7D7D'}' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");}`}</style>
                <li
                    style={listItemStyle}
                    key={todo.id}
                    onClick={e => toggleTodo(todo.id, !todo.completed)}
                >
                    <input type="checkbox" checked={todo.completed}
                           onClick={e => toggleTodo(todo.id, e.target.checked)}/>
                    <div dangerouslySetInnerHTML={{ __html: todo.title }} />
                    <span className={'label'}>{formatDistanceToNow(new Date(todo.createdAt))}</span>
                    <button className={'btn delete-btn'}
                            onClick={() => deleteTodo(todo.id)}>{trash()}</button>
                </li>
            </>)
        })}
    </ul>)
}