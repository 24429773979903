import {trash, columns, column, compact, cozy, dark, settings as settingsIcon} from "./icons";
import React from "react";

export default function Toolbar({settings, setSettings}) {
    return (<div style={{
        zIndex: 2,
        position: 'relative'
    }} className={'toolbar'}>
        <button
            className={'btn'}
            style={{background: settings.menu ? '#333' : 'transparent'}}
            onClick={() => setSettings({...settings, ...{menu: !settings.menu}})}>{settingsIcon(settings.menu ? '#FFF' : '#000')}</button>
        <span className="separator">&nbsp;</span>
        <button
            className={'btn'}
            style={{background: settings.compact ? '#333' : 'transparent'}}
            onClick={() => setSettings({...settings, ...{compact: true}})}>{compact(settings.compact ? '#FFF' : '#000')}</button>
        <button
            className={'btn'}
            style={{background: !settings.compact ? '#333' : 'transparent'}}
            onClick={() => setSettings({...settings, ...{compact: false}})}>{cozy(!settings.compact ? '#FFF' : '#000')}</button>
        <span className="separator">&nbsp;</span>
        <button
            className={'btn'}
            style={{background: settings.cols === 1 ? '#333' : 'transparent'}}
            onClick={() => setSettings({...settings, ...{cols: 1}})}>{column(settings.cols === 1 ? '#FFF' : '#000')}</button>
        <button
            className={'btn'}
            style={{background: settings.cols === 2 ? '#333' : 'transparent'}}
            onClick={() => setSettings({...settings, ...{cols: 2}})}>{columns(settings.cols === 2 ? '#FFF' : '#000')}</button>
        <span className="separator">&nbsp;</span>
        <button
            className={'btn'}
            style={{background: settings.dark ? '#333' : 'transparent'}}
            onClick={() => setSettings({...settings, ...{dark: settings.dark ? !settings.dark : true}})}>{dark(settings.dark ? '#FFF' : '#000')}</button>
    </div>)
}