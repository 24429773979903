import React from "react";

const trash = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 26 32"
        >
            <path
                fill="#a30000"
                d="M8 10h2v16H8zM12 10h2v16h-2zM16 10h2v16h-2zM0 4h26v2H0zM18 4h-2V3a.94.94 0 00-.88-1H11a.94.94 0 00-1 .88V4H8V3a3 3 0 013-3h4a3 3 0 013 3z"
            ></path>
            <path
                fill="#a30000"
                d="M19 32H7a3.33 3.33 0 01-3.2-2.9L2 5.1l2-.2 1.8 24A1.2 1.2 0 007 30h12a1.28 1.28 0 001.2-1.1l1.8-24 2 .2-1.8 24A3.33 3.33 0 0119 32z"
            ></path>
        </svg>
    )
}

const columns = (color) => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 37.84 36.87"
    >
        <rect
            width={9.53}
            height={26.5}
            x={7.43}
            y={5.09}
            rx={1.74}
            style={{
                fill: "none",
                stroke: color,
                strokeMiterlimit: 10,
                strokeWidth: 3,
            }}
        />
        <rect
            width={9.53}
            height={26.5}
            x={20.88}
            y={5.28}
            rx={1.74}
            style={{
                fill: "none",
                stroke: color,
                strokeMiterlimit: 10,
                strokeWidth: 3,
            }}
        />
        <path
            d="M0 0h37.84v36.87H0z"
            style={{
                fill: "none",
            }}
        />
    </svg>)
}

const column = (color) => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 37.84 36.87"
    >
        <rect
            width={9.53}
            height={26.5}
            x={14.16}
            y={5.19}
            rx={1.74}
            style={{
                fill: "none",
                stroke: color,
                strokeMiterlimit: 10,
                strokeWidth: 3,
            }}
        />
        <path
            d="M0 0h37.84v36.87H0z"
            style={{
                fill: "none",
            }}
        />
    </svg>)
}

const compact = (color) => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 37.84 36.87"
    >
        <rect
            width={6.56}
            height={26.5}
            x={15.64}
            y={-4.62}
            rx={1.74}
            style={{
                fill: "none",
                stroke: color,
                strokeMiterlimit: 10,
                strokeWidth: 3,
            }}
            transform="rotate(90 18.92 8.63)"
        />
        <rect
            width={6.56}
            height={26.5}
            x={15.73}
            y={14.99}
            rx={1.74}
            style={{
                fill: "none",
                stroke: color,
                strokeMiterlimit: 10,
                strokeWidth: 3,
            }}
            transform="rotate(90 19.01 28.24)"
        />
        <rect
            width={6.56}
            height={26.5}
            x={15.55}
            y={5.22}
            rx={1.74}
            style={{
                fill: "none",
                stroke: color,
                strokeMiterlimit: 10,
                strokeWidth: 3,
            }}
            transform="rotate(90 18.825 18.465)"
        />
        <path
            d="M0 0h37.84v36.87H0z"
            style={{
                fill: "none",
            }}
        />
    </svg>)
}

const cozy = (color) => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 37.84 36.87"
    >
        <rect
            width={9.53}
            height={26.5}
            x={14.2}
            y={-1.96}
            rx={1.74}
            style={{
                fill: "none",
                stroke: color,
                strokeMiterlimit: 10,
                strokeWidth: 3,
            }}
            transform="rotate(90 18.97 11.29)"
        />
        <rect
            width={9.53}
            height={26.5}
            x={14.11}
            y={12.33}
            rx={1.74}
            style={{
                fill: "none",
                stroke: color,
                strokeMiterlimit: 10,
                strokeWidth: 3,
            }}
            transform="rotate(90 18.875 25.575)"
        />
        <path
            d="M0 0h37.84v36.87H0z"
            style={{
                fill: "none",
            }}
        />
    </svg>)
}

const dark = (color) => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 37.84 36.87"
    >
        <circle
            cx={18.92}
            cy={18.43}
            r={9.25}
            style={{
                fill: "none",
                stroke: color,
                strokeMiterlimit: 10,
                strokeWidth: 3,
            }}
        />
        <path
            d="M0 0h37.84v36.87H0z"
            style={{
                fill: "none",
            }}
        />
        <path
            d="M33.85 18.43a14.93 14.93 0 0 1-14.93 14.93c-19.8-.79-19.79-29.07 0-29.85a14.93 14.93 0 0 1 14.93 14.92Z"
            style={{
                fill: "none",
                stroke: color,
                strokeLinecap: "round",
                strokeMiterlimit: 6,
                strokeWidth: 3,
                strokeDasharray:
                    "5.32693338394165,5.32693338394165,1.0653866529464722,5.32693338394165,5.32693338394165,1.0653866529464722",
            }}
        />
    </svg>)
}

const settings = (color) => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            enableBackground: "new 0 0 489.802 489.802",
        }}
        viewBox="0 0 489.802 489.802">
        <path d="m20.701 281.901 32.1.2c4.8 24.7 14.3 48.7 28.7 70.5l-22.8 22.6c-8.2 8.1-8.2 21.2-.2 29.4l24.6 24.9c8.1 8.2 21.2 8.2 29.4.2l22.8-22.6c21.6 14.6 45.5 24.5 70.2 29.5l-.2 32.1c-.1 11.5 9.2 20.8 20.7 20.9l35 .2c11.5.1 20.8-9.2 20.9-20.7l.2-32.1c24.7-4.8 48.7-14.3 70.5-28.7l22.6 22.8c8.1 8.2 21.2 8.2 29.4.2l24.9-24.6c8.2-8.1 8.2-21.2.2-29.4l-22.6-22.8c14.6-21.6 24.5-45.5 29.5-70.2l32.1.2c11.5.1 20.8-9.2 20.9-20.7l.2-35c.1-11.5-9.2-20.8-20.7-20.9l-32.1-.2c-4.8-24.7-14.3-48.7-28.7-70.5l22.8-22.6c8.2-8.1 8.2-21.2.2-29.4l-24.6-24.9c-8.1-8.2-21.2-8.2-29.4-.2l-22.8 22.6c-21.6-14.6-45.5-24.5-70.2-29.5l.2-32.1c.1-11.5-9.2-20.8-20.7-20.9l-35-.2c-11.5-.1-20.8 9.2-20.9 20.7l-.3 32.1c-24.8 4.8-48.8 14.3-70.5 28.7l-22.6-22.8c-8.1-8.2-21.2-8.2-29.4-.2l-24.8 24.6c-8.2 8.1-8.2 21.2-.2 29.4l22.6 22.8c-14.6 21.6-24.5 45.5-29.5 70.2l-32.1-.2c-11.5-.1-20.8 9.2-20.9 20.7l-.2 35c-.1 11.4 9.2 20.8 20.7 20.9zm158.6-103.3c36.6-36.2 95.5-35.9 131.7.7s35.9 95.5-.7 131.7-95.5 35.9-131.7-.7-35.9-95.5.7-131.7z" style={{
            fill: color,
        }}/>
    </svg>)
}

const close = (color) =>    <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 384 384.2"
>
    <path d="m379.65 322.9-131.2-131.7L380 61.2a13.88 13.88 0 0 0 0-19.6L342.55 4a13.76 13.76 0 0 0-9.8-4A14.19 14.19 0 0 0 323 4L192.05 133.6 61 4.1a13.76 13.76 0 0 0-9.8-4 14.19 14.19 0 0 0-9.8 4L4.05 41.7a13.88 13.88 0 0 0 0 19.6l131.5 130L4.45 322.9a13.85 13.85 0 0 0-4.1 9.8 13.45 13.45 0 0 0 4.1 9.8l37.4 37.6a13.78 13.78 0 0 0 9.8 4.1 13.54 13.54 0 0 0 9.8-4.1l130.6-131.2L322.75 380a13.78 13.78 0 0 0 9.8 4.1 13.54 13.54 0 0 0 9.8-4.1l37.4-37.6a13.85 13.85 0 0 0 4.1-9.8 14.34 14.34 0 0 0-4.2-9.7Z" style={{
        fill: color,
    }} />
</svg>

export {
    trash,
    columns,
    column,
    compact,
    cozy,
    dark,
    settings,
    close
}
