import {useState, useEffect} from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const InputForm = ({item, setItem, setTodos}) => {
    const [isMobile, setIsMobile] = useState(false);

    const formateDate = (timestamp) => {
        return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'short', day: '2-digit'}).format(timestamp)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setTodos(currentTodos => {
            return [...currentTodos.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)), {
                id: crypto.randomUUID(),
                title: item,
                completed: false,
                createdAt: Date.now(),
                createdAtDate: formateDate(Date.now())
            }]
        })

        setItem('')
    }

    useEffect(() => {
        const checkIsMobile = () => {
            const isMobileDevice = /iPhone|iPad|iPod|Android|BlackBerry|Windows Phone/i.test(navigator.userAgent);
            setIsMobile(isMobileDevice);
        };

        checkIsMobile();
    }, []);
    let lastKeyPressTime = 0;

    return <>
        <ReactQuill theme="snow" value={item} onChange={setItem} onKeyUp={(e) => {
            if (e.ctrlKey && e.key === 'Enter') {
                handleSubmit(e)
            }
        } }  />
        {!isMobile && <p className={'note'}>Ctrl+Enter to save the item</p>}
        {isMobile && <input style={{
            zIndex: 2,
            position: "relative",
            borderRadius: '4px',
            padding:' 0.25em 0.5em'
        }} type="button" value={"Enter"} onClick={handleSubmit} />}
    </>
};

export default InputForm;
