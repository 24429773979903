import {formatDistanceToNow} from "date-fns";
import {column, trash} from "./icons";
import {useRef, useEffect} from 'react';


export default function MultipleColumns({settings, todos, toggleTodo, deleteTodo}) {

    const groupBy = (items, key) => {
        return items.reduce(function (item, i) {
            (item[i[key]] = item[i[key]] || []).push(i);
            return item;
        }, {});
    };

    const groupedTodos = groupBy(todos, 'createdAtDate')

    const sortByDate = (a, b, direction) => {
        const [month, day, year] = a.split(" ")
        const [prevMonth, prevDay, prevYear] = b.split(" ")
        const date = new Date(year, "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1, day.slice(0, -1))
        const prevDate = new Date(prevYear, "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(prevMonth) / 3 + 1, prevDay.slice(0, -1))
        return direction === 'asc' ? prevDate - date : date - prevDate
    }

    const columnRef = useRef(null);
    const listRef = useRef(null);

    useEffect(() => {
        const column = columnRef.current;
        const list = listRef.current;
        const handleScroll = (event) => {
            if (event.key === 'ArrowLeft') {
                column.scrollLeft -= 225;
            } else if (event.key === 'ArrowRight') {
                column.scrollLeft += 225;
            } else if (event.key === 'ArrowUp') {
                column.scrollTop -= 1000;
            } else if (event.key === 'ArrowDown') {
                column.scrollTop += 1000;
            }
        };

        column.addEventListener('keydown', handleScroll);

        return () => {
            column.removeEventListener('keydown', handleScroll);
        };
    }, []);


    return (<div ref={columnRef} className={'columns'}>
        {Object.keys(groupedTodos).length > 0 &&
        Object.keys(groupedTodos).sort((a, b) => sortByDate(a, b, 'asc')).map((key, index) => {
            return (<ul key={key.replace(' ', '').replace(', ','') + '_' + index} className={'list'}>
                {groupedTodos[key].sort((a, b) => b.createdAt - a.createdAt).map((todo, i) => {
                    const listItemStyle = {
                        backgroundColor: todo.completed ? 'rgba(0,0,0,.2)' : 'rgba(255,255,255,.2)',
                        padding: settings.compact ? '2em .75em' : '3em 1em'
                    }

                    return (<div key={todo.id +'_'+ i}>
                        {i === 0 && <p className={'message'}>{new Date(key).toDateString()}</p>}
                        <style>{`ul li::before{background-color:${settings.dark ? '#FFF' : '#7D7D7D'}} ul li::after{background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%23${settings.dark ? 'FFF' : '7D7D7D'}' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");}`}</style>
                        <li
                            style={listItemStyle}
                            onClick={e => toggleTodo(todo.id, !todo.completed)}
                        >
                            <input type="checkbox" checked={todo.completed}
                                   onChange={e => toggleTodo(todo.id, e.target.checked)}/>
                            <div dangerouslySetInnerHTML={{ __html: todo.title }} />
                            <span
                                className={'label'}>{formatDistanceToNow(new Date(todo.createdAt))}</span>
                            <button className={'btn delete-btn'}
                                    onClick={() => deleteTodo(todo.id)}>{trash()}</button>
                        </li>
                    </div>)
                })}
            </ul>)
        })}
    </div>)
}