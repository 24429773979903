import React from 'react';
import {close} from "./components/icons";

const Container = ({settings, setSettings, children}) => {

    const {
        background,
        blur,
        opacity,
        menu,
    } = settings

    const mainStyle = {
        color: settings.dark ? '#fff' : '#333',
        backgroundImage: `url('${background}')`,
        backgroundRepeat: 'repeat',
        position: 'relative'
    }

    const imageOptions = [
        'https://cdn.pixabay.com/photo/2017/08/20/10/47/grey-2661270_1280.png',
        'https://cdn.pixabay.com/photo/2017/02/15/20/33/floral-2069810_1280.png',
        'https://cdn.pixabay.com/photo/2017/07/08/11/33/white-2484120_1280.png',
        'https://cdn.pixabay.com/photo/2018/05/02/09/04/blue-3368023_1280.png',
    ]

    return (
        <div style={mainStyle}>
            <div className="overlay" style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 1,
                backdropFilter: `blur(${blur}px)`,
                WebkitBackdropFilter: `blur(${blur}px)`,
                background: settings.dark ? `rgba(0, 0, 0, ${opacity})` : `rgba(255, 255, 255, ${opacity})`,
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)'
            }}>&nbsp;</div>
            <div className={`settings ${menu ? 'slideIn' : 'slideOut'}`} style={{
                animation: menu && '250ms slideIn',
                background: settings.dark ? `rgba(255, 255, 255, ${opacity})` : `rgba(0, 0, 0, ${opacity})`,
                color: settings.dark ? '#000' : '#FFF',
            }}>
                <button
                    className={'close-btn btn'}
                    onClick={() => setSettings({...settings, ...{menu: !settings.menu}})}>{close(settings.dark ? '#000' : '#FFF')}</button>
                <div className="settings-form">
                    <div className="input-group">
                        <label htmlFor="background">Background</label>
                        <input id="background" type="text" value={background}
                               onChange={(e) => setSettings({...settings, ...{background: e.target.value}})}
                        />
                    </div>
                    <div className="select-background">
                        {imageOptions.map((item) => {
                            return (<div
                                key={item}
                                className="rect"
                                data-image={item}
                                style={{
                                    height: '100px',
                                    width: '200px',
                                    backgroundImage: `url('${item}')`,
                                    backgroundSize: 'contain'
                                }}
                                onClick={(e) => setSettings({...settings, ...{background: e.currentTarget.getAttribute('data-image')}})}
                            >&nbsp;</div>)
                        })}
                    </div>

                    <div className="input-group">
                        <label htmlFor="blur">Blur</label>
                        <input
                            id={'blur'}
                            type="range"
                            value={blur}
                            step={0.5}
                            min={0}
                            max={50}
                            onChange={(e) => setSettings({...settings, ...{blur: e.target.value}})}
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="blur">Opacity</label>
                        <input
                            id={'opacity'}
                            type="range"
                            value={opacity}
                            step={0.01}
                            max={1}
                            min={0}
                            onChange={(e) => setSettings({...settings, ...{opacity: e.target.value}})}
                        />
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
};

export default Container;