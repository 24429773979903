import "./App.scss"
import React, {useState} from "react";
import Container from "./Container";
import NoItems from "./components/NoItems";
import Toolbar from "./components/Toolbar";
import InputForm from "./components/InputForm";
import useLocalStorage from "./useLocalStorage";
import SingleColumn from "./components/SingleColumn";
import MultipleColumns from "./components/MultipleColumns";

const App = () => {
    const [item, setItem] = useState('')
    const [todos, setTodos] = useLocalStorage('todos', [])
    const [settings, setSettings] = useLocalStorage('settings', {
        dark: true,
        compact: true,
        cols: 2,
        background: 'https://picsum.photos/1920',
        blur: 5,
        opacity: .3,
        menu: false
    })

    const toggleTodo = (id, completed) => {
        setTodos(currentTodos => {
            return currentTodos.map(todo => {
                if (todo.id === id) {
                    return {...todo, completed}
                }
                return todo
            })
        })
    }

    const deleteTodo = (id, completed) => {
        setTodos(currentTodos => {
            return currentTodos.filter(todo => todo.id !== id)
        })
    }

    return (<Container settings={settings} setSettings={setSettings}>
        <div className="content">
            <InputForm item={item} setItem={setItem} setTodos={setTodos}/>
            <Toolbar settings={settings} setSettings={setSettings}/>
            <div style={{
                zIndex: 2,
                position: 'relative'
            }} className="content-inner">
                {todos.length === 0 && <NoItems/>}
                {todos.length > 0 && settings.cols === 1 &&
                <SingleColumn settings={settings} todos={todos} toggleTodo={toggleTodo} deleteTodo={deleteTodo}/>}
                {todos.length > 0 && settings.cols === 2 &&
                <MultipleColumns settings={settings} todos={todos} toggleTodo={toggleTodo} deleteTodo={deleteTodo}/>}
            </div>
        </div>
    </Container>);
};

export default App;